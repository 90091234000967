import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Badge from "shared/Badge/Badge";
import { SimpleToken } from "app/types";

export interface CardNFTProps {
  className?: string;
  isLiked?: boolean;
  token: SimpleToken;
}

const CardNFT: FC<CardNFTProps> = ({ className = "", isLiked, token }) => {
  const [nftImage, setNftImage] = useState<string>();
  useEffect(() => {
    // try {
    //   require(`../../../public/images/nfts/${token?.collection}/${token?.identifier}.png`);
    //   setNftImage(`/images/nfts/${token?.collection}/${token?.identifier}.png`);
    // } catch (err) {
    setNftImage(token?.thumbnailUrl);
    // }
  }, [token]);

  return (
    <div
      className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardNFT"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <Badge
            className="absolute top-4 left-3 !font-semibold z-10"
            name={`🏆 #${token.rank ?? 1533}`}
            color={"gray"}
          />
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden will-change-transform"
            src={nftImage}
            // src={token.media.thumbnailUrl}
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out rounded-3xl will-change-transform"
          />
        </div>

        <LikeButton
          liked={isLiked}
          likedCount={token.likeCount}
          className="absolute top-3 right-3 z-10 !h-9"
        />
        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>

      <div className="p-4 py-5 space-y-3">
        <div className="flex justify-between">
          {/* {renderAvatars()} */}
          <span className="text-neutral-700 dark:text-neutral-400 text-xs">
            {token.identifier}
          </span>
        </div>
        <h2 className={`text-lg font-medium`}>{token.name}</h2>
      </div>

      <Link
        to={`/nft-detail/${token.identifier}`}
        className="absolute inset-0"
      ></Link>
    </div>
  );
};

export default CardNFT;
