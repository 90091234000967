import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import SectionSubscribe from "components/SectionSubscribe/SectionSubscribe";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionHero from "components/SectionHero/SectionHero";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>eNeFTy Market || Elrond NFT MarketPlace</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative mt-12 mb-20 sm:mb-24 sm:mt-20 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero />

        {/* SECTION 2 */}
        <SectionHowItWork className="mt-24 lg:mt-40 xl:mt-48" />
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <SectionSliderCollections />
        </div>

        {/* SECTION */}
        <SectionSubscribe />
      </div>
    </div>
  );
}

export default PageHome;
