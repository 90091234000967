import React, { FC } from "react";
import MainNavLogged from "./MainNavLogged";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
    <div className="nc-HeaderLogged relative w-full z-40 ">
      {/* NAV */}
      <MainNavLogged />
    </div>
  );
};

export default HeaderLogged;
