import { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import authorBanner from "images/nfts/authorBanner.png";
import FollowButton from "components/FollowButton";
import { Tab } from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useParams } from "react-router-dom";
import { BigNumber } from "bignumber.js";
import { setUsername } from "app/reducers/walletSlice";
import { SimpleToken } from "app/types";

const { REACT_APP_ELROND_API_GATEWAY } = process.env;
BigNumber.set({ DECIMAL_PLACES: 8 });

export interface ProfilePageProps {
  className?: string;
}

export interface Account {
  address: string;
  nonce: number;
  balance: string;
  rootHash: string;
  txCount: number;
  scrCount: number;
  username?: string;
  shard: number;
  developerReward: string;
}

export interface nftMedia {
  url: string;
  originalUrl: string;
  thumbnailUrl: string;
  fileType: string;
  fileSize: number;
}

export interface Token {
  identifier: string;
  collection: string;
  nonce: number;
  name: string;
  url: string;
  type: string;
  tags: string[];
  media: nftMedia;
  likeCount: number;
  rank?: number;
}

export interface ProfileCard {
  title: string;
  value: string;
}

interface RouteParams {
  profileId: string;
}

const ProfilePage: FC<ProfilePageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const params = useParams<RouteParams>();
  let [categories] = useState([
    "My Collection",
    // "Following",
  ]);

  let address = "";
  const [account, setAccount] = useState<Account>();
  const [cards, setCards] = useState<ProfileCard[]>([]);
  const wallet = useAppSelector((state) => state.wallet);
  // const [accountId, setAccountId] = useState("");
  const [data, setData] = useState<SimpleToken[]>([]);

  // const cards = [
  //   {
  //     title: "Balance",
  //     value: "3.45 EGLD",
  //   },
  // ];

  useEffect(() => {
    address = params.profileId === "my" ? wallet.address : params.profileId;
    fetch(
      `${REACT_APP_ELROND_API_GATEWAY}/accounts/${address}/nfts?type=NonFungibleESDT`
    )
      .then((response) => response.json())
      .then((nfts) => setData(nfts));
  }, []);

  useEffect(() => {
    address = params.profileId === "my" ? wallet.address : params.profileId;
    fetch(`${REACT_APP_ELROND_API_GATEWAY}/accounts/${address}`)
      .then((response) => response.json())
      .then((acc) => {
        setAccount(acc);
        dispatch(setUsername(acc.username ?? acc.address));
        const balance = new BigNumber(acc.balance).dividedBy(
          1000000000000000000
        );
        setCards([{ title: "EGLD Balance", value: `${balance.toFixed()}` }]);
      });
  }, []);

  return (
    <div className={`nc-ProfilePage  ${className}`} data-nc-id="ProfilePage">
      <Helmet>
        <title>Profile || eNFTy NFT Template</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={authorBanner}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="container -mt-10 lg:-mt-16">
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
            <div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0">
              <NcImage
                src={""}
                containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
              />
            </div>
            <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
              <div className="max-w-screen-sm ">
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>
                    {account?.username &&
                      `@${account?.username?.replace(".elrond", "")}`}
                  </span>
                  {/* <VerifyIcon
                    className="ml-2"
                    iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                  /> */}
                </h2>

                <div className="flex items-center text-sm font-medium space-x-2.5 mt-2.5 text-green-600 cursor-pointer">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {account?.address}{" "}
                  </span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                      d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-4 xl:mt-6 grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 xl:gap-6">
                {/* ----- 1 ----- */}
                {cards.map((card) => (
                  <div
                    className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6"
                    key={card.title}
                  >
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                      {card.title}
                    </span>
                    <span className="font-medium text-base mt-2 sm:text-xl sm:mt-2">
                      {card.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {wallet.address !== account?.address && (
              <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end">
                <FollowButton
                  isFollowing={false}
                  fontSize="text-sm md:text-base font-medium"
                  sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          <Tab.Group>
            <div className="flex flex-col lg:flex-row justify-between ">
              <Tab.List className="flex space-x-0 sm:space-x-2 overflow-x-auto ">
                {categories.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${
                          selected
                            ? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
                            : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
                        } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              {/* <div className="mt-5 lg:mt-0 flex items-end justify-end">
                <ArchiveFilterListBox />
              </div> */}
            </div>
            <Tab.Panels>
              <Tab.Panel className="">
                {/* LOOP ITEMS */}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8 lg:mt-10">
                  {data.map((token) => (
                    <CardNFT key={token.identifier} token={token} />
                  ))}
                </div>

                {/* PAGINATION */}
                {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  <Pagination />
                  <ButtonPrimary loading>Show me more</ButtonPrimary>
                </div> */}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </div>
    </div>
  );
};

export default ProfilePage;
