import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TopCollection } from "app/types";

const initialState: TopCollection[] = [];

const topCollectionsSlice = createSlice({
  name: "topCollections",
  initialState,
  reducers: {
    setTopCollections(state, action: PayloadAction<TopCollection[]>) {
      state = action.payload;
    },
  },
});

export const { setTopCollections } = topCollectionsSlice.actions;
export default topCollectionsSlice.reducer;
