import { TopCollection } from "app/types";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import VerifyIcon from "./VerifyIcon";

export interface CollectionCardProps {
  className?: string;
  imgs?: string[];
  collection?: TopCollection;
}

const CollectionCard: FC<CollectionCardProps> = ({
  className,
  imgs = [],
  collection,
}) => {
  return (
    <div className={`CollectionCard group relative ${className}`}>
      <div className="relative flex flex-col rounded-2xl overflow-hidden">
        <NcImage containerClassName="aspect-w-8 aspect-h-5" src={imgs[0]} />
        <div className="grid grid-cols-3 gap-1.5 mt-1.5">
          {[1, 2, 3].map((index) => (
            <NcImage
              containerClassName="w-full h-28"
              src={imgs[index]}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className="relative mt-5 ">
        {/* TITLE */}
        <h2 className="font-semibold text-2xl group-hover:text-primary-500 transition-colors">
          {collection?.name} {"asdas"}
        </h2>
        {/* AUTHOR */}
        <div className="mt-2 flex justify-between">
          <div className="flex items-center  truncate">
            <Avatar sizeClass="h-6 w-6" />
            <div className="ml-2 text-sm truncate">
              <span className="font-normal hidden sm:inline-block"></span>
              <span className="font-medium">{collection?.collection}</span>
            </div>
            <VerifyIcon iconClass="w-4 h-4" />
          </div>
          <span className="mb-0.5 ml-2 inline-flex justify-center items-center px-2 py-1.5 border-2 border-secondary-500 text-secondary-500 rounded-md text-xs !leading-none font-medium">
            {collection?.count} Items
          </span>
        </div>
      </div>
      <Link
        to={`/page-collection/${collection?.collection}`}
        className="absolute inset-0 "
      ></Link>
    </div>
  );
};

export default CollectionCard;
