import mediaRunningReducer from "./mediaRunning/mediaRunning";
import walletReducer from "app/reducers/walletSlice";
import { elrondApiSlice } from "app/reducers/elrondApiReducer";
import { eneftyApiSlice } from "./reducers/eneftyApiReducer";
import topCollectionReducer from "./reducers/topCollectionReducer";

const rootReducers = {
  mediaRunning: mediaRunningReducer,
  wallet: walletReducer,
  topCollections: topCollectionReducer,
  [elrondApiSlice.reducerPath]: elrondApiSlice.reducer,
  [eneftyApiSlice.reducerPath]: eneftyApiSlice.reducer,
};

export default rootReducers;
