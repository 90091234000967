import { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import VerifyIcon from "components/VerifyIcon";
import TabDetail, { NftAttribute } from "./TabDetail";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { Link, useParams } from "react-router-dom";
import { NonFungibleToken } from "app/reducers/elrondApiReducer";
import { useFetchNftDetailsQuery } from "app/reducers/eneftyApiReducer";

interface RouteParams {
  identifier: string;
}

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  token?: NonFungibleToken;
}

const NftDetailPage: FC<NftDetailPageProps> = ({ className = "" }) => {
  const params = useParams<RouteParams>();
  let [nft, setNft] = useState<NonFungibleToken>();
  const [nftImage, setNftImage] = useState<string>();

  const [nftAttributes, setNftAttributes] = useState<NftAttribute[]>([]);

  const { data } = useFetchNftDetailsQuery(params.identifier);

  useEffect(() => {
    setNft(data);
    // try {
    //   // require(`../../../public/images/nfts/${nft?.collection}/${nft?.identifier}.png`);
    //   setNftImage(`/images/nfts/${nft?.collection}/${nft?.identifier}.png`);
    // } catch (err) {
    setNftImage(data?.media[0].thumbnailUrl);
    // }
    if (data) {
      if (data?.metadata?.attributes) {
        setNftAttributes(data?.metadata?.attributes);
      } else {
        try {
          const result = JSON.parse(atob(data?.attributes ?? "[]"));
          setNftAttributes(result.attributes);
        } catch (err) {}
      }
    }
  }, [data]);

  const renderSection1 = () => {
    return (
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        {/* ---------- 1 ----------  */}
        <div className="pb-9 space-y-5">
          <div className="flex justify-between items-center">
            {nft?.tags &&
              nft?.tags.map((tag) => {
                return <Badge name={tag} color="green" />;
              })}
            {!nft?.tags && <Badge name={nft?.collection} color="green" />}
            <LikeSaveBtns />
          </div>
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {nft?.name}
          </h2>

          {/* ---------- 4 ----------  */}
          <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
            <div className="flex items-center">
              <Avatar
                imgUrl={nftImage}
                sizeClass="h-9 w-9"
                radius="rounded-full"
              />
              <Link to={`/page-collection/${nft?.collection}`}>
                <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                  <span className="text-sm">Collection</span>
                  <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                    <span>{nft?.collection}</span>
                    <VerifyIcon iconClass="w-4 h-4" />
                  </span>
                </span>
              </Link>
            </div>
          </div>
        </div>

        {/* ---------- 9 ----------  */}
        <div className="pt-9">
          <TabDetail nftAttributes={nftAttributes} owner={nft?.creator} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-NftDetailPage  ${className}`}
      data-nc-id="NftDetailPage"
    >
      {/* MAIn */}
      <main className="container mt-11 flex ">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="relative">
              <NcImage
                src={nftImage}
                containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
              />
              {/* META FAVORITES */}
              <LikeButton className="absolute right-3 top-3 " />
            </div>

            <AccordionInfo />
          </div>

          {/* SIDEBAR */}
          <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            {renderSection1()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default NftDetailPage;
