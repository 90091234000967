import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface WebWalletLoginProps {
  className?: string;
}

const WebWalletLogin: FC<WebWalletLoginProps> = () => {
  const handleOnClick = () => {};

  return (
    <div>
      <ButtonPrimary className="flex-1" onClick={handleOnClick}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.5 12H3.67004"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="ml-2">Go Back Home</span>
      </ButtonPrimary>
    </div>
  );
};

export default WebWalletLogin;
