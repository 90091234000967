import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ElrondCollection,
  ElrondTopCollection,
  NftsCollectionQuery,
  SimpleToken,
  TopCollection,
  ElrondSearchCollection,
} from "app/types";
import { NonFungibleToken } from "./elrondApiReducer";

const { REACT_APP_API_ENDPOINT } = process.env;

export const eneftyApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_API_ENDPOINT}`,
    prepareHeaders(headers) {
      // headers.set("x-api-key", "");
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      searchCollections: builder.query<ElrondSearchCollection[], string>({
        query(keyword) {
          return `/external/collections?keyword=${keyword}`;
        },
      }),
      fetchTopCollections: builder.query<ElrondTopCollection[], string>({
        query(frequency) {
          return `/external/top-collections?frequency=${frequency}`;
        },
      }),
      fetchNftDetails: builder.query<NonFungibleToken, string>({
        query(tokenIdentifier) {
          return `/internal/nfts/${tokenIdentifier}`;
        },
      }),
      fetchCollectionDetails: builder.query<ElrondCollection, string>({
        query(collectionId) {
          return `/external/collections/${collectionId}`;
        },
      }),
      fetchCollectionNfts: builder.query<SimpleToken[], NftsCollectionQuery>({
        query(query) {
          let url = `/external/collections/${query.collectionId}/nfts?from=${
            query.pageFrom
          }&size=${query.pageSize ?? 100}`;
          if (query.filter && query.filter.length > 0) {
            url += `&filter=${query.filter}`;
          }
          return url;
        },
      }),
    };
  },
});

export const {
  useSearchCollectionsQuery,
  useFetchTopCollectionsQuery,
  useFetchNftDetailsQuery,
  useFetchCollectionNftsQuery,
  useFetchCollectionDetailsQuery,
} = eneftyApiSlice;
