import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { NftsCollectionQuery } from "app/types";

export interface Media {
  url: string;
  originalUrl: string;
  thumbnailUrl: string;
  fileType: string;
  fileSize: number;
}

export interface NonFungibleToken {
  identifier: string;
  collection: string;
  attributes: string;
  nonce: number;
  type: string;
  name: string;
  creator: string;
  royalties: number;
  uris: string[];
  url: string;
  media: Media[];
  isWhitelistedStorage: boolean;
  tags: string[];
  metadata: any;
  ticker: string;
  likeCount: number;
  rank?: number;
}

export interface Account {
  address: string;
  nonce: number;
  balance: string;
  rootHash: string;
  txCount: number;
  scrCount: number;
  username: string;
  shard: number;
  developerReward: string;
}

export interface NftCollection {
  collection: string;
  type: string;
  name: string;
  ticker: string;
  owner: string;
  timestamp: number;
  canFreeze: boolean;
  canWipe: boolean;
  canPause: boolean;
  canTransferNftCreateRole: boolean;
  totalCount: number;
  mintedCount: number;
}

export interface NftCollectionPaged {
  nfts?: NonFungibleToken[];
  pageFrom: number;
  pageSize: number;
  totalCount: number;
}

const { REACT_APP_ELROND_API_GATEWAY } = process.env;

export const elrondApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_ELROND_API_GATEWAY}`,
    prepareHeaders(headers) {
      // headers.set("x-api-key", "");
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchAccountNfts: builder.query<NonFungibleToken[], string>({
        query(accountId) {
          return `/accounts/${accountId}/nfts?type=NonFungibleESDT`;
        },
      }),
      fetchAccountDetails: builder.query<Account, string>({
        query(accountId) {
          return `/accounts/${accountId}`;
        },
      }),
      fetchCollectionNfts: builder.query<
        NonFungibleToken[],
        NftsCollectionQuery
      >({
        query(query) {
          return `collections/${query.collectionId}/nfts?from=${
            query.pageFrom
          }&size=${query.pageSize ?? 100}`;
        },
      }),
      fetchCollectionNftsPaged: builder.query<
        NftCollectionPaged,
        NftsCollectionQuery
      >({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          // get a random user
          const nfts = await fetchWithBQ(
            `collections/${_arg.collectionId}/nfts?from=${_arg.pageFrom}&size=${
              _arg.pageSize ?? 100
            }`
          );
          const totalCount = await fetchWithBQ(
            `collections/${_arg.collectionId}/nfts/count`
          );
          return totalCount.data
            ? {
                data: {
                  nfts: nfts.data,
                  totalCount: totalCount.data,
                  pageFrom: _arg.pageFrom,
                  pageSize: _arg.pageSize,
                } as NftCollectionPaged,
              }
            : { error: totalCount.error as FetchBaseQueryError };
        },
      }),
      // searchCollections: builder.query<NftCollection[], string>({
      //   query(query) {
      //     return `/collections?search=${query}&type=NonFungibleESDT`;
      //   },
      // }),
    };
  },
});

export const {
  useFetchAccountNftsQuery,
  useFetchAccountDetailsQuery,
  // useFetchCollectionNftsQuery,
  // useSearchCollectionsQuery,
  useFetchCollectionNftsPagedQuery,
} = elrondApiSlice;
