import { FC } from "react";
import { Tab } from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";

export interface TabDetailProps {
  nftAttributes?: NftAttribute[];
  owner?: string;
}

export interface NftAttribute {
  trait_type: string;
  value: string;
}

//{"attributes":[{"trait_type":"Background","value":"Stealth"},{"trait_type":"Weapon","value":"Swords of Fire"},{"trait_type":"Skin","value":"Urus"},{"trait_type":"Tattoo","value":"No Tattoo"},{"trait_type":"Armour","value":"Arena"},{"trait_type":"Horns","value":"Bandaged Horns"},{"trait_type":"Earing","value":"No Earing"},{"trait_type":"Nose","value":"Bronze Ring"}]}

const TabDetail: FC<TabDetailProps> = ({ nftAttributes = [], owner = "" }) => {
  // const [attributes, setAttributes] = useState<NftAttribute[]>();
  const TABS = ["Attributes", "Transaction History", "Owner"];

  // useEffect(() => {
  //   debugger;
  //   if (nftAttributes) {
  //     const result = JSON.parse(atob(nftAttributes));
  //     setAttributes(result.attributes);
  //   }
  // }, []);

  const renderTabAttributes = () => {
    return (
      <div className="mt-6 xl:mt-8 grid grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 xl:gap-6">
        {nftAttributes.map((attribute: NftAttribute) => (
          <div className="rounded-xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {attribute.trait_type}
            </span>
            <span className="font-medium text-base mt-2 sm:text-l sm:mt-2">
              {attribute.value}
            </span>
            <span className="text-xs text-green-500 mt-1">+2.11%</span>
          </div>
        ))}
      </div>
      // <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
      //   {nftAttributes.map((attribute: NftAttribute) => (
      //     <li
      //       key={attribute.trait_type}
      //       className={`relative py-4  "bg-neutradl-100"`}
      //     >
      //       <div className="flex items-center">
      //         <span className="ml-4 text-neutral-500 dark:text-neutral-400 flex flex-col">
      //           <span className="flex items-center text-sm">
      //             <span className="">{attribute.trait_type}</span>
      //             {/* <span className="">
      //                 {Math.random() > 0.5 ? "Listed by" : "Minted by"}
      //               </span> */}

      //             <span className="font-medium text-neutral-900 dark:text-neutral-200 ml-1">
      //               {attribute.value}
      //             </span>
      //           </span>
      //         </span>
      //       </div>
      //     </li>
      //   ))}
      // </ul>
    );
  };

  const renderTabTransactionHistory = () => {
    return (
      <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
        {nftAttributes?.map((attribute: NftAttribute) => (
          <li
            key={attribute.trait_type}
            className={`relative py-4 "bg-neutradl-100" `}
          >
            <div className="flex items-center">
              <Avatar sizeClass="h-10 w-10" radius="rounded-full" />
              <span className="ml-4 text-neutral-500 dark:text-neutral-400 flex flex-col">
                <span className="flex items-center text-sm">
                  <span className="">
                    {Math.random() > 0.5 ? "Listed by" : "Minted by"}
                  </span>

                  <span className="font-medium text-neutral-900 dark:text-neutral-200 ml-1">
                    {attribute.value}
                  </span>
                </span>
                <span className="text-xs mt-1">Jun 14 - 4:12 PM</span>
              </span>
            </div>

            <span className="absolute inset-0 rounded-md focus:z-10 focus:outline-none focus:ring-2 ring-blue-400"></span>
          </li>
        ))}
      </ul>
    );
  };

  const renderTabOwner = () => {
    return (
      <div className="flex items-center py-4">
        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
          <span className="text-sm">Owner</span>
          <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
            <span>{owner}</span>
          </span>
        </span>
      </div>
    );
  };

  const renderTabItem = (item: string) => {
    switch (item) {
      case "Attributes":
        return renderTabAttributes();

      case "Transaction History":
        return renderTabTransactionHistory();

      case "Provenance":
        return renderTabTransactionHistory();

      case "Owner":
        return renderTabOwner();

      default:
        return null;
    }
  };

  return (
    <div className="w-full pdx-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full bordedr border-neutral-300 dark:border-neutral-500">
          {TABS.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 font-medium rounded-full focus:outline-none focus:ring-2 ring-primary-300 ${
                  selected
                    ? "bg-neutral-900 dark:bg-neutral-100 text-white dark:text-neutral-900"
                    : "text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100"
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {TABS.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={
                "rounded-xl focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 "
              }
            >
              {renderTabItem(tab)}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabDetail;
