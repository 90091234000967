import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getItem, removeItem, setItem } from "app/session";

export interface WalletState {
  loading: boolean;
  error: string;
  loggedIn: boolean;
  address: string;
  username: string;
  denomination: number;
  decimals: number;
}

export interface Attribute {
  trait_type: string;
  value: string;
}

const initialState: WalletState = {
  loading: false,
  error: "",
  loggedIn: !!getItem("logged_in"),
  address: getItem("address"),
  username: "",
  denomination: 18,
  decimals: 2,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    login(state, action: PayloadAction<string>) {
      state.address = action.payload;
      state.loggedIn = true;
      setItem("logged_in", true);
      setItem("address", action.payload);
    },
    logout(state) {
      removeItem("logged_in");
      removeItem("address");
      state.address = "";
      state.username = "";
      state.loggedIn = false;
    },
    setUsername(state, action: PayloadAction<string>) {
      let username = action.payload;
      if (username.indexOf(".elrond") > 0) {
        state.username = `@${username.replace(".elrond", "")}`;
      } else {
        state.username = username;
      }
    },
  },
});

export const { login, logout, setUsername } = walletSlice.actions;
export default walletSlice.reducer;
